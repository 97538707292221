import _get from "lodash/get";
import { createItemEvent } from "../../../lib/analytics-accounts/eventsUtilities";
import { AnalyticsActions } from "../../../lib/analytics-accounts/utils";
import {
  CartCustomItem,
  CartFragment,
  CartSimpleItem,
  ProductType,
  ShippingDestinationsQuery,
} from "../../../generated/graphql";
import { PhoneInput } from "../../../shared/globals/UiElements/InputPhone";
import { ShippingDestination } from "../../UtilityComponents/ShippingDestinationSelect";

export type ShippingDestinationsMap = Map<
  string,
  {
    id: string;
    parentId?: string;
    name: string;
    children?: string[];
    hasChildren: boolean;
  }
>;

export interface CheckoutInformation {
  address: string;
  apartment: string;
  email?: string;
  name: string;
  postalCode?: string | null;
  phone?: PhoneInput;
  secondPhone?: PhoneInput;
  notes?: string;
  isSubscribed?: boolean;
  selectedAddressId?: string;
  shippingDestination: ShippingDestination;
}

function sum(arr: CartFragment["items"][0][], property: string) {
  return (
    arr
      ?.map((item) => _get(item, property))
      .reduce((acc, curr) => acc + curr, 0) || 1
  );
}

export function fireBeginCheckoutEvent(cart: CartFragment) {
  let collections = cart.items.reduce(
    (acc, curr) => [
      ...acc,
      ...(curr?.product?.collections?.nodes?.map(
        (collection) => collection?.title
      ) || []),
    ],
    []
  );

  collections = Array.from(new Set(collections));

  AnalyticsActions.fireEvent({
    name: "Begin_Checkout",
    parameters: {
      event_category: "Checkout",
      content_type: "product",
      content_ids: cart?.items?.map((item) => {
        const isItemSimple = item?.product?.type === ProductType.Simple;
        const typedSimpleItem = item as CartSimpleItem;
        const typedCustomItem = item as CartCustomItem;
        return isItemSimple
          ? typedSimpleItem?.variant?.id
          : typedCustomItem?.product?.id;
      }),
      num_items: sum(cart?.items, "quantity"),
      value: cart?.receipt?.subtotal?.amount,
      currency: cart?.receipt?.subtotal?.currencyCode,
      totalPrice: cart?.receipt?.total?.amount,
      ItemNames: cart?.items?.map((item) => item.product?.title),
      items: cart?.items?.map((item) =>
        createItemEvent(item, cart?.customer?.id)
      ),
      collections,
      buyerName: cart?.contactInfo?.name,
      buyerEmail: cart?.contactInfo?.email,
      buyerPhone: cart?.contactInfo?.phone,
    },
  });
}

export function getShippingDestinations(
  data: ShippingDestinationsQuery | undefined
) {
  const shippingDestinationsMap: ShippingDestinationsMap = new Map();
  data?.store?.shippingZones?.nodes?.forEach((zone) => {
    zone?.countries?.nodes?.forEach((country) => {
      shippingDestinationsMap.set(country?.id!, {
        id: country?.id!,
        parentId: undefined,
        name: country?.name!,
        children: [
          ...(shippingDestinationsMap.get(country?.id!)?.children || []),
          ...(country?.states?.nodes?.map((state) => state?.id!) || []),
        ],
        hasChildren: !!country?.states?.totalCount,
      });

      country?.states?.nodes?.forEach((state) => {
        shippingDestinationsMap.set(state?.id!, {
          id: state?.id!,
          parentId: country?.id!,
          name: state?.name!,
          children: state?.cities?.nodes?.map((city) => city?.id!),
          hasChildren: !!state?.cities?.totalCount,
        });
        state?.cities?.nodes?.forEach((city) => {
          shippingDestinationsMap.set(city?.id!, {
            id: city?.id!,
            parentId: state?.id!,
            name: city?.name!,
            children: city?.regions?.nodes?.map((region) => region?.id!),
            hasChildren: !!city?.regions?.totalCount,
          });
          city?.regions?.nodes?.forEach((region) => {
            shippingDestinationsMap.set(region?.id!, {
              id: region?.id!,
              parentId: city?.id!,
              name: region?.name!,
              children: undefined,
              hasChildren: false,
            });
          });
        });
      });
    });
  });

  return shippingDestinationsMap;
}

export function isShippingDestinationAvailable(
  shippingDestinationsMap: ShippingDestinationsMap,
  areaShippingDetails: any
) {
  let isShippingDestinationAvailable = true;

  const keys: string[] = [];

  for (const key in areaShippingDetails)
    if (key.endsWith("Id") && areaShippingDetails[key] !== null) keys.push(key);

  const country = shippingDestinationsMap.get(
    areaShippingDetails?.countryId || ""
  );
  const state = shippingDestinationsMap.get(areaShippingDetails?.stateId || "");
  const city = shippingDestinationsMap.get(areaShippingDetails?.cityId || "");
  const region = shippingDestinationsMap.get(
    areaShippingDetails?.regionId || ""
  );

  keys.forEach((key) => {
    if (key === "countryId" && !country) {
      isShippingDestinationAvailable = false;
    } else if (key === "stateId" && !state) {
      isShippingDestinationAvailable = false;
    } else if (key === "cityId" && !city) {
      isShippingDestinationAvailable = false;
    } else if (key === "regionId" && !region) {
      isShippingDestinationAvailable = false;
    }
  });
  if (!areaShippingDetails) isShippingDestinationAvailable = false;
  return isShippingDestinationAvailable;
}
